import * as React from "react"
import cx from "classnames"

import "./Button.scss"

type Props = {
  onClick: () => void
  children: string | JSX.Element
  classname?: string
}

export default function Button(props: Props) {
  const classname = cx("button button-component", {
    [props.classname]: props.classname,
  })
  return (
    <button onClick={props.onClick} className={classname}>
      {props.children}
    </button>
  )
}
