import { Icon, Intent } from "@blueprintjs/core"
import * as React from "react"
import Button from "./Button"
import { useAuth0 } from "@auth0/auth0-react"

import "./Header.scss"

const Header = ({ children }) => {
  const onMeButtonClick = () => {
    console.log("clicked")
  }

  const { loginWithRedirect, getAccessTokenSilently, isAuthenticated, getAccessTokenWithPopup, user, isLoading } = useAuth0()

  const onLoginButtonClick = async () => {
    if (isAuthenticated) {
      return
    } else {
      try {
        await loginWithRedirect()
      } catch (e) {
        console.log(e)
      }
    }
  }

  const icon = (
    <svg width="36" height="36" viewBox="0 0 441 458" fill="none">
      <g clip-path="url(#clip0)">
        <path
          d="M387 260.5C387 365.158 301.934 450 197 450C92.0659 450 7 365.158 7 260.5C197 260.5 92.0659 71 197 71C301.934 71 387 155.842 387 260.5Z"
          fill="#FFB26B"
        />
        <path
          d="M434 260.5C434 365.158 349.158 450 244.5 450C139.842 450 55 365.158 55 260.5C244.5 260.5 139.842 71 244.5 71C349.158 71 434 155.842 434 260.5Z"
          fill="#FF2A61"
        />
        <circle cx="232.5" cy="209.5" r="19.5" fill="white" />
        <path
          d="M339 243.307C339 257.497 324.897 269 307.5 269C290.103 269 276 257.497 276 243.307C276 229.117 290.103 243.306 307.5 243.306C324.897 243.306 339 229.117 339 243.307Z"
          fill="white"
        />
        <ellipse cx="375" cy="209.5" rx="20" ry="19.5" fill="white" />
        <path
          d="M221 74C197.3 72.6811 148.32 59.7554 142 18.6043C165.7 12.0096 214.68 13.8561 221 74Z"
          fill="#3DD3AA"
        />
        <path
          d="M285.117 11.324C279.19 34.3145 256.953 79.8368 215.42 78.0017C213.598 53.466 224.986 5.78056 285.117 11.324Z"
          fill="#3DD3AA"
        />
      </g>
    </svg>
  )

  const meIcon = (
    <svg
      width="19"
      height="23"
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4751 5.66367C15.4751 8.62875 13.0714 11.0324 10.1063 11.0324C7.14122 11.0324 4.73755 8.62875 4.73755 5.66367C4.73755 2.69859 7.14122 0.294922 10.1063 0.294922C13.0714 0.294922 15.4751 2.69859 15.4751 5.66367ZM12.7906 5.66389C12.7906 4.18135 11.5888 2.97951 10.1063 2.97951C8.62372 2.97951 7.42188 4.18135 7.42188 5.66389C7.42188 7.14643 8.62372 8.34827 10.1063 8.34827C11.5888 8.34827 12.7906 7.14643 12.7906 5.66389Z"
        fill="black"
      />
      <path
        d="M9.56938 12.106C4.52875 12.106 0.442505 16.3124 0.442505 21.5013C0.442505 22.2425 1.02625 22.8435 1.74634 22.8435C2.46644 22.8435 3.05018 22.2425 3.05018 21.5013C3.05018 17.7949 5.96893 14.7903 9.56938 14.7903C13.1698 14.7903 16.0886 17.7949 16.0886 21.5013C16.0886 22.2425 16.6723 22.8435 17.3924 22.8435C18.1125 22.8435 18.6963 22.2425 18.6963 21.5013C18.6963 16.3124 14.61 12.106 9.56938 12.106V12.106Z"
        fill="black"
      />
    </svg>
  )

  return (
    <>
      <div className="header is-flex is-justify-content-space-between">
        <div className="is-flex is-align-items-center">
          <div className="header__logo mr-2">
            <div>{icon}</div>
          </div>
          <div className="has-text-weight-bold is-size-4">mangomart</div>
        </div>
        <div>
          <div
            onClick={onMeButtonClick}
            className="button has-background-white header__profile--button mr-4"
          >
            <div className="is-flex is-align-items-center">
              <div className="header__profile--icon mr-2">{meIcon}</div>
              <div className="has-text-weight-bold">Profile</div>
            </div>
          </div>
          <Button
            onClick={() => null}
            classname="header__sell-button is-mint-jelly has-text-white has-text-weight-bold"
          >
            Sell
          </Button>
        </div>
      </div>
      {children}
    </>
  )
}

export default Header